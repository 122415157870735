import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import { Layout } from "../components/atoms/Layout"
import PartDetails from "../components/organisms/Part/Details"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import PartHighlights from "../components/organisms/Part/Highlights"
import PartBanner from "../components/organisms/Part/Banner"
import PartInfo from "../components/organisms/Part/Info"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import FAQ from "../components/molecules/FAQ/FAQ"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import tw from "twin.macro"
import Coupons from "../components/molecules/Coupons/Coupons"
import { Find } from "../components/molecules/Find"
import { LocationContext } from "../contexts/Location"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../contexts/Tealium"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import _ from "lodash"
import { Coupon } from "../components/molecules/Coupons/Coupons.d"
import Header from "../components/molecules/Heros/Header"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import Articles from "../components/organisms/Part/Articles"
import { LanguageContext } from "../contexts/Language"
import VideoSection from "../components/molecules/VideoSection/Video"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const PartPage: React.FC<PageProps> = ({
  data,
  location,
  pageContext,
}: any) => {
  const { page, articles, articleRoutes } = data
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    // For Spanish translation Parts Detail pages, the pageName variable is assigned the "name" value from the referenced English page for that part.
    // With Sanity v3 upgrade, English page names are passed from createPage context inside gatsby-node.js
    const pageName = pageContext?.enDocName || page.name
    let formattedPart = pageName.split(" ").join("_").toLowerCase()

    if (formattedPart == "takata_airbag_safety_recall") {
      formattedPart = "takata_safety_airbag_recall"
    }

    if (pageload.current && isOutOfArea) {
      pageload.current = false
      // Out of area only applies to parts pages that _are not_ the Takata Airbag Recall

      handleTealView({
        page_name: pageName.toLowerCase(),
        tealium_event: `parts_service_${formattedPart}`,
        page_type: "parts service",
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
        search_results: 0,
      })
    } else if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: pageName.toLowerCase(),
        tealium_event: `parts_service_${formattedPart}`,
        page_type: "parts service",
        dealer_code: dealers.map(dealer => dealer.DealerCode),
        dealer_name: dealers.map(dealer => dealer.Name),
        search_results: dealers?.length,
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <Layout tealium={{ label: "partsDetailPage" }}>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections
        data={page}
        articles={articles.nodes}
        articleRoutes={articleRoutes}
        page={page}
      />
    </Layout>
  )
}

const SanitySections = ({
  data,
  articles,
  articleRoutes,
}: any): JSX.Element => {
  const { tealPageData } = useTealiumContext()
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null

  // Find the section with _type "partsAndServiceHero"
  const partsAndServiceHeroData = data?.content?.find(
    (section: any) => section._type === "partsAndServiceHero"
  )

  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero
                  section={section}
                  icon={data.icon}
                  {...retentionSalesEventHeroData}
                />
              )
            }
            return (
              <Header
                section={section}
                icon={data.icon}
                aria-label="Parts & Services Hero Section"
              />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <>
                  <RetentionSalesEventHero
                    section={section}
                    icon={data.icon}
                    {...retentionSalesEventHeroData}
                  />
                  <h1 css={[tw`sr-only`]}>
                    {partsAndServiceHeroData.headline.textHeadline}
                  </h1>
                </>
              )
            }
            return <PartAndServiceHero {...section} />
          case "partBannerSection":
            return <PartBanner {...section} />
          case "partDetailSection":
            return (
              <PartDetails
                {...section}
                coupons={data?.coupons}
                name={data.name}
              />
            )
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
          case "imageBlocks":
            return <ImageBlocks {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "videoSection":
            return <VideoSection {...section} />
          case "faqSection":
            return <FAQ {...section} expanded showTitle={false} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId={
                  tealPageData.page_name == "tires"
                    ? "shop tires"
                    : "schedule service"
                }
              />
            )
          case "iFrameUrlSection":
            return (
              <section
                aria-label="Parts iFrame Section"
                css={[tw`mx-8 my-8`, tw`md:(max-w-5xl mx-auto my-20)`]}
              >
                <iframe src={section.iFrameUrl} height="5500" width="1170" />
              </section>
            )
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "couponsSection":
            return <Coupons {...section} />
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "articles":
            return (
              articles?.length > 0 && (
                <Articles
                  data={articles}
                  articleRoutes={articleRoutes}
                  {...section}
                />
              )
            )
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query PartPageQuery($id: String, $lang: String) {
    page: sanityPagePart(id: { eq: $id }) {
      id
      language
      name
      title
      slug
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      description
      icon
      coupons {
        name
        expires
        offer
        image {
          ...SanityImageData
        }
        starts
      }
      image {
        asset {
          altText
          _id
          gatsbyImageData
        }
      }
      content {
        ...HeaderData
        ...VideoSectionData
        ...PartBannerData
        ...PartDetailData
        ...PartInfoData
        ...PartsImageBlocksData
        ...FaqSectionData
        ...DealersNearYouData
        ...IFrameUrlSectionData
        ...ToyotaCareData
        ...CouponsSectionData
        ...CallToActionBoxData
        ...TabbedImageModuleData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...ArticlesSectionData
        ...BrochureData
      }
      disclaimers {
        code
        disclaimer
      }
      #i18n_base {
      #  ... on SanityPagePart {
      #    name
      #  }
      #}
    }
    articles: allSanityPageArticle(
      filter: { language: { eq: $lang }, part: { id: { eq: $id } } }
    ) {
      nodes {
        part {
          name
          id
        }
        categories {
          category
        }
        language
        _type
        _key
        _id
        title
        featuredImage {
          ...SanityImageData
        }
      }
    }
    articleRoutes: allSanityRoute {
      nodes {
        page {
          ... on SanityPageArticle {
            _type
            _id
            #i18n_refs {
            #  ... on SanityPageArticle {
            #    _id
            #    id
            #    language
            #  }
            #}
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default PartPage
